import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    try {
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        const options = { method: "GET", headers: headers };
        const response = await fetch(graphConfig.graphMeEndpoint, options);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        // Handle or throw the error based on your application's needs
    }
}
