import { useState, useEffect, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../utils/authConfig';
import { callMsGraph } from '../utils/graph';
import { fetchUserDataFromFunction } from '../services/azureFunctions';

export const useUserSubscriptions = () => {
    const { instance, accounts } = useMsal();
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [retryCount, setRetryCount] = useState(0);

    const fetchUserDataAndDashboards = useCallback(() => {
        if (!accounts.length) return;

        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        })
        .then((response) => {
            callMsGraph(response.accessToken).then((graphResponse) => {
                const userId = graphResponse.id;
                fetchUserDataFromFunction(userId)
                    .then(data => {
                        setSubscriptionData(data);
                        setLoading(false);
                    })
                    .catch(err => {
                        if (err.message.includes("uninitialized_public_client_application") && retryCount < 3) {
                            setTimeout(() => {
                                setRetryCount(prevRetry => prevRetry + 1);  // Increase the retry count
                                fetchUserDataAndDashboards();  // Retry the request
                            }, 2000); // Delay for 2 seconds
                        } else {
                            console.error('Error fetching subscription data:', err);
                            setError(err.message);
                            setLoading(false);
                        }
                    });
            });
        })
        .catch(err => {
            if (err.message.includes("uninitialized_public_client_application") && retryCount < 3) {
                setTimeout(() => {
                    setRetryCount(prevRetry => prevRetry + 1);  // Increase the retry count
                    fetchUserDataAndDashboards();  // Retry the request
                }, 2000); // Delay for 2 seconds
            } else {
                setError(err.message);
                setLoading(false);
            }
        });
    }, [instance, accounts, retryCount]);

    useEffect(() => {
        if (accounts.length > 0) {
            fetchUserDataAndDashboards();
        }
    }, [fetchUserDataAndDashboards, accounts]);

    return { subscriptionData, loading, error };
};



