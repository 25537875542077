import React, { useState, useEffect } from 'react';
import { WiThermometer, WiHumidity, WiStrongWind } from 'react-icons/wi';
import '../../styles/Weather.css';
import weatherCodeToIcon from './weatherCodeMappings';

const Weather = () => {
  const [location, setLocation] = useState('Fetching location...');
  const [weatherData, setWeatherData] = useState(null); // New state for weather data

  useEffect(() => {
    // Function to fetch weather data
    const fetchWeatherData = async (latitude, longitude) => {
      const endpoint = `${process.env.REACT_APP_WEATHER_ENDPOINT}?lat=${latitude}&lon=${longitude}`;
      try {
        const response = await fetch(endpoint);
        const data = await response.json();
        setWeatherData(data); // Set the weather data
      } catch (error) {
        //console.error('Error fetching weather data:', error);
      }
    };

    // Function to get the current location of the user
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;

          // Fetch location name
          const geocodeEndpoint = `${process.env.REACT_APP_GEOCODE_ENDPOINT}?lat=${latitude}&lon=${longitude}`;
          try {
            const response = await fetch(geocodeEndpoint);
            const data = await response.json();
            if (data.locationName) {
              setLocation(data.locationName);
            } else {
              setLocation('Location name not found');
            }
          } catch (error) {
            //console.error('Error fetching location:', error);
            setLocation('Error fetching location');
          }

          // Fetch weather data
          fetchWeatherData(latitude, longitude);
        }, (error) => {
          //console.error('Geolocation permission denied:', error);
          setLocation('Location permission denied');
        });
      } else {
        setLocation('Geolocation not supported');
      }
    };

    getLocation();
  }, []);

  // Get today's date in the desired format
  const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const friendlyDate = new Date().toLocaleDateString('en-US', dateOptions);

  return (
    <div className="weather-container">
      <span>{friendlyDate}</span>
      <div>{location}</div>
      {weatherData ? (
        <>
          <div className="weather-icon">
            {weatherCodeToIcon(weatherData.weatherCode)}
          </div>
          <div className="weather-icon"><WiThermometer size={24} color="#FFFFFF" /> {weatherData.temperature}°C</div>
          <div className="weather-icon"><WiHumidity size={24} color="#FFFFFF" /> {weatherData.relativeHumidity}%</div>
          <div className="weather-icon"><WiStrongWind size={24} color="#FFFFFF" /> {weatherData.windSpeed} km/h</div>
        </>
      ) : (
        <div>Loading weather data...</div>
      )}
    </div>
  );
};

export default Weather;










