// Import React and useState and useEffect hooks.
import React, { useState, useEffect, useCallback } from 'react';


import { PageLayout } from './components/Layout/PageLayout';
import { loginRequest } from './utils/authConfig';
import { callMsGraph } from './utils/graph';
import { ProfileData } from './components/Profile/ProfileData';
import { SignInButton } from "./components/Auth/SignInButton.jsx";
import { fetchUserDataFromFunction } from './services/azureFunctions';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useUserSubscriptions } from './hooks/useUserSubscriptions';
import { BeatLoader } from 'react-spinners';

import DashboardTile from './components/UI/DashboardTile';
import NewsFeed from './components/NewsFeed/NewsFeed';
import Weather from './components/Weather/Weather.jsx';
import FarmingForumDashboard from './pages/Dashboard/FarmingForum.jsx';

import './styles/App.css';

// ProfileContent Component: Displays user profile content after fetching and processing user data.
const ProfileContent = () => {
    // MSAL hooks to get current instance and authenticated accounts.
    const { instance, accounts } = useMsal();
    // Hook to check if the authentication is in progress.
    const { inProgress } = useIsAuthenticated();
    // States for storing fetched graph data and subscription data.
    const [graphData, setGraphData] = useState(null);
    const [subscriptionData, setSubscriptionData] = useState([]);
    // State to check if MSAL has been initialized.
    const [msalInitialized, setMsalInitialized] = useState(false);
    const [isError, setIsError] = useState(false);
    
    // Effect hook to set msalInitialized to true once accounts data is present.
    useEffect(() => {
        if (accounts.length > 0) {
            setMsalInitialized(true);
        }
    }, [accounts]);

    // Function to request and process profile data.
    const requestProfileData = useCallback(async () => {
        //console.log('Inside requestProfileData.');
        try {
            // Acquire silent token for the logged-in user.
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            });
            // Call Microsoft Graph API with acquired token.
            const graphResponse = await callMsGraph(response.accessToken);
            setGraphData(graphResponse);
            // Fetch user subscription data using Graph API response.
            const data = await fetchUserDataFromFunction(graphResponse.id);
            setSubscriptionData(data);
        } catch (error) {
            //console.error('Error:', error);
            setIsError(true);
        }
    }, [instance, accounts]);

    // Effect hook to trigger profile data request once MSAL is initialized.
    useEffect(() => {
        if (msalInitialized && instance && inProgress !== 'startup') {
            //console.log('MSAL is initialized. Requesting profile data.');
            requestProfileData();
        }
    }, [msalInitialized, instance, accounts, requestProfileData, inProgress]);
    return (
        <>
            <br/>
            {isError && (
                <div>
                    <p>Error loading profile data. Please try refreshing the page.</p>
                    <p>If the issue persists, contact us <a href="/help">here</a>.</p>
                </div>
            )}
            {graphData && subscriptionData.length > 0 && !isError ? (
                <ProfileData graphData={graphData} subscriptionData={subscriptionData} />
            ) : null}
        </>
    );
};

// HomePage Component: Main landing page that displays user's dashboards or sign-in button.
const HomePage = () => {
    // Hook to get user subscriptions, loading state, and any errors.
    const { subscriptionData, loading, error } = useUserSubscriptions();
    const { inProgress } = useIsAuthenticated();
    const [dataFetched, setDataFetched] = useState(false);

    // Effect hook to set data as fetched once authentication is not in 'startup' mode.
    useEffect(() => {
        if (inProgress !== 'startup' && !dataFetched) {
            setDataFetched(true);
        }
    }, [inProgress, dataFetched]);

    return (
        <div className="App">
            <AuthenticatedTemplate>
                <Weather />
                <br/>
                <h2 className="dashboard-heading">Your Dashboards</h2>
                {loading && <div className="spinner-centered"><BeatLoader color="#204B1C" /></div>}
                {error && (
                    <div>
                        <p>Error loading dashboards: {error}</p>
                        <p>Please try refreshing the page. If the issue persists, contact us.</p>
                    </div>
                )}
                <div className="dashboard-container">
                    {subscriptionData.map((sub, idx) => (
                        <DashboardTile key={sub.id || idx} dashboard={sub} />
                    ))}
                </div>

                {/* Separate heading for news with a friendly date format */}
                <br/>
                <h2 className="news-heading">News</h2>
                <NewsFeed /> {/* NewsFeed component without the internal header */}

            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className="unauthenticated-container">
                    <SignInButton />
                </div>
            </UnauthenticatedTemplate>
        </div>
    );
};

// PrivateWrapper Component: Renders children if user is authenticated, otherwise redirects to home.
function PrivateWrapper({ children }) {
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return children;
    }

    return <Navigate to="/" />;
}

// App Component: Main application component that sets up routes and page layout.
export default function App() {
    const { inProgress } = useIsAuthenticated();

    // Display a spinner while MSAL is in startup phase.
    if (inProgress === "startup") {
        return <div className="spinner-centered"><BeatLoader color="#204B1C" /></div>;
    }

    // Only render the following JSX if MSAL is not in the "startup" phase:

    return (
        <Router>
            <PageLayout>
                <Routes>
                    <Route path="/account" element={
                        <PrivateWrapper>
                            <ProfileContent />
                        </PrivateWrapper>
                    } />
                    <Route path="/dashboard/farmingforum" element={
                        <PrivateWrapper>
                            <FarmingForumDashboard />
                        </PrivateWrapper>
                    } />
                    <Route path="/" element={<HomePage />} />
                    {/* Catch-all route */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </PageLayout>
        </Router>
    );
}