import '../../styles/Profile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'; // Importing calendar icon

export const ProfileData = (props) => {
  return (
    <div className="profile-container">
      <h2>{props.graphData.givenName} {props.graphData.surname}</h2>
      <p className="email">{props.graphData.userPrincipalName}</p>
      
      <h4>Your Subscriptions:</h4>
      <div className="subscriptions">
          {props.subscriptionData.map((sub, index) => (
              <div key={index} className="subscription-card">
                  <h5>{sub.DashboardName}</h5>
                  <p>
                    <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" /> 
                    Start: {new Date(sub.SubscriptionStart).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
                    End: {new Date(sub.SubscriptionEnd).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}
                  </p>
              </div>
          ))}
      </div>
    </div>
  );
};



