import React from "react";
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../utils/authConfig";
import Button from "react-bootstrap/Button";  // Import the Button component
import "../../styles/App.css";
import "../../styles/Login.css"

export const SignInButton = () => {
  const { instance } = useMsal();

  useEffect(() => {
    document.body.classList.add("full-width-div")
  
    return function cleanup() {
      document.body.classList.remove('full-width-div');
    };
  }, []);

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  document.body.classList.add('full-width-div');

 


  return (
    <div  >
    <div style={{textAlign: "center"}}>
    <img src="/headerLogo.png" style={{height: "1.5rem"}} alt="Logo of the company"></img>
    <div className="login-page">
  <div className="form">
  <Button className="auth-button ml-auto" onClick={handleLogin}>
      Log In
  </Button>
  <p className="message">Not registered? <a href="https://agriverse.co.uk/">Request access</a></p>
  </div>
</div>
</div>
</div>
  );
};

