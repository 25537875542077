import React from 'react';
import { Link } from "react-router-dom";
import '../../styles/DashboardTile.css';

// Import images from assets
import FarmingForum from '../../assets/FarmingForum.png';

const DashboardTile = ({ dashboard }) => {
    // Mapping dashboard names to their respective paths:
    const dashboardPaths = {
        FarmingForumDashboard: { path: "/dashboard/farmingforum", img: FarmingForum },
        // Add more dashboards here if needed
    };

    const currentDashboard = dashboardPaths[dashboard.DashboardName];
    
    // Check if the dashboard exists in the dashboardPaths
    if (!currentDashboard) {
        // Handle the case where the dashboard is not found
        //console.error(`Dashboard not found: ${dashboard.DashboardName}`);
        return null; // or render some fallback UI
    }

    // Replacing 'Dashboard' with '' and adding space before each capital letter (except the first one)
    const title = dashboard.DashboardName
                    .replace('Dashboard', '')
                    .replace(/([A-Z])/g, ' $1')
                    .trim()
                    .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter if it's lowercased

    return (
        <div className="dashboard-tile">
            <Link to={currentDashboard.path} className="dashboard-link">
                <img src={currentDashboard.img} alt={title} className="dashboard-image" />
                <h3 className="dashboard-title">{title}</h3>
                <p><strong>Subscription End:</strong> {new Date(dashboard.SubscriptionEnd).toLocaleDateString()}</p>
            </Link>
        </div>
    );   
}

export default DashboardTile;

