import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";  // Import the Button component
import "../../styles/App.css";

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "https://app.agriverse.co.uk/",
    });
  };

  return (
    <Button className="auth-button ml-auto" onClick={handleLogout}>
      Sign Out
    </Button>
  );
};
