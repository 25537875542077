import React, { useState, useEffect } from 'react';
import { fetchPowerBITokenFromFunction, fetchPowerBIEmbedToken } from '../../services/azureFunctions';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

// Import the CSS file
import '../../styles/dashboards.css';

const REPORT_ID = process.env.REACT_APP_FARMINGFORUM_REPORT_ID;

const FarmingForumDashboard = () => {
    const [token, setToken] = useState(null);
 
    const isMobile = () => {
        const isMobileDevice = window.innerWidth <= 800 || window.innerHeight <= 600;
        //console.log(`isMobile: ${isMobileDevice}`); // Debugging line
        return isMobileDevice;
    }

    useEffect(() => {
        // Try getting the token from sessionStorage
        const storedToken = sessionStorage.getItem('universalPowerBIEmbedToken');
        const storedTokenExp = sessionStorage.getItem('universalPowerBIEmbedToken_EXP');


        if (storedToken == null || storedTokenExp == null) 
        {
            //console.log('Fetching Power BI access token...');
            
            fetchPowerBITokenFromFunction()
                .then(fetchedAccessToken => {
                    //console.log('Successfully fetched Power BI access token.');
                    //console.log('Fetching universal Power BI embed token for all reports and datasets...');
    
                    return fetchPowerBIEmbedToken(fetchedAccessToken);
                })
                .then(embedTokenData => {
                    //console.log('Successfully fetched universal Power BI embed token.');
                    setToken(embedTokenData.token);
 
                    // Store the universal token in sessionStorage
                    sessionStorage.setItem('universalPowerBIEmbedToken', embedTokenData.token);
                    sessionStorage.setItem('universalPowerBIEmbedToken_EXP', embedTokenData.expiration);
                    console.log("TOKEN initialised at start!");
                })
                .catch(error => {
                    //console.error('Error:', error);
                });
 
        }
        else{
            console.log("Session re-initialised")
            setToken(storedToken);
        }

        
    }, []);
    


    // Renew when the token time is smaller than 10 minutes till expiry
    const MINUTES_BEFORE_EXPIRATION = 10;


    
    //cannot be used due to the use effect. Need to be moved outside the component to work
  //  if (!token) {
        //console.log('Token not fetched yet. Displaying loading message...');
  //      return <div>Loading...</div>;
  //  }
 
    
    // Set the refresh interval time to 30 seconds
    const INTERVAL_TIME = 30000;


    //Start loop that executes every 'INTERVAL_TIME'. Destroy on page close/change to avoid promise error.
    useEffect(() => { let interval; interval = setInterval(() => 
        {
            function checkVal(){
                const storedTokenExp = sessionStorage.getItem('universalPowerBIEmbedToken_EXP');
                if(!storedTokenExp != null){ //if not null time, calculate. if it's null initialise.
                    const currentTime = Date.now();
                    const expiration = Date.parse(storedTokenExp);
        
                    // Time until token expiration in milliseconds
                    const timeUntilExpiration = expiration - currentTime;
                    const timeToUpdate = MINUTES_BEFORE_EXPIRATION * 60 * 1000;
        
                    if (timeUntilExpiration <= timeToUpdate)
                    {
                        console.log("Updating report access token");
                        updateToken();
                    }
                }
            }
        
            async function updateToken(){
                await fetchPowerBITokenFromFunction()
                .then(fetchedAccessToken => {
                    //console.log('Successfully fetched Power BI access token.');
                    //console.log('Fetching universal Power BI embed token for all reports and datasets...');
        
                    return fetchPowerBIEmbedToken(fetchedAccessToken);
                })
                .then(embedTokenData => {
                    //console.log('Successfully fetched universal Power BI embed token.');
                    window.report.setAccessToken(embedTokenData.token);
                    console.log("TOKEN GOT from re-newing!");
                });
            }
        
            checkVal()
        
        }, INTERVAL_TIME); return () => clearInterval(interval);},[]);

    
     
    // Determine the layout type based on the device
    const layoutType = isMobile() ? models.LayoutType.MobilePortrait : models.LayoutType.Custom;


    const embedConfig = {
        type: 'report',
        id: REPORT_ID,
        embedUrl: 'https://app.powerbi.com/reportEmbed',
        accessToken: token,
        tokenType: models.TokenType.Embed,
        settings: {
            layoutType: layoutType,
            panes: {
                filters: {
                    expanded: false,
                    visible: true
                }
            },
            navContentPaneEnabled: false
        }
    };
    

    console.log(`Embed Config Layout Type: ${embedConfig.settings.layoutType}`); // Debugging line

    const eventHandlers = new Map([
        ['loaded', function () {
            //console.log('Report loaded');
        }],
        ['rendered', function () {
            //console.log('Report rendered');
        }],
        ['error', function (event) {
            //console.error('Error event in PowerBIEmbed:', event.detail);
        }]
    ]);

    if(token != null){
    return (
        <div className="PowerBIdashboard-container"> 
            <PowerBIEmbed 
                embedConfig={embedConfig} 
                eventHandlers={eventHandlers}
                cssClassName="report-style-class"
                getEmbeddedComponent={(embeddedReport) => {
                    //console.log('Embedded report instance received:', embeddedReport);
                    window.report = embeddedReport;
                }}
            />
        </div>
    );
            }
    else{
        return(<div className="container d-flex my-auto align-items-center justify-content-center text-center">
        <div>
        <div className="column mb-3"><h5>Loading , please wait!</h5></div>
        <div style={{width: "3rem" , height: "3rem", "marginBottom": "20rem"}} className="spinner-border text-success column" role="status">
                <span className="sr-only"></span>
        </div>
        </div></div>);
    }
}

export default FarmingForumDashboard;