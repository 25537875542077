const MAX_RETRIES = 3;
const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_URL;

// Function to fetch User Data
export async function fetchUserDataFromFunction(userId) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: userId })
    };
    return await attemptFetch(`${BACKEND_ENDPOINT}/fetchUserData`, options, 0);
}

// Function to fetch News Data
export async function fetchNewsFromFunction() {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return await attemptFetch(`${BACKEND_ENDPOINT}/fetchNews`, options, 0);
}

// Function to fetch Power BI Token
export async function fetchPowerBITokenFromFunction() {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return await attemptFetch(`${BACKEND_ENDPOINT}/fetchPowerBIToken`, options, 0);
}

// Function to fetch Power BI Embed Token for all reports and datasets
export async function fetchPowerBIEmbedToken(accessToken) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ accessToken: accessToken })
    };
    return await attemptFetch(`${BACKEND_ENDPOINT}/fetchPowerBIEmbedToken`, options, 0);
}

// Helper function to attempt fetch with retries
async function attemptFetch(endpoint, options, attempts) {
    try {
        const response = await fetch(endpoint, options);

        if (response.ok) {
            return await response.json();
        }

        throw new Error(`Failed to fetch data. Status: ${response.status}`);
    } catch (error) {
        if (attempts < MAX_RETRIES) {
            await new Promise(resolve => setTimeout(resolve, 1000 * (attempts + 1))); // Waits for 1 second, then 2 seconds, then 3 seconds...
            return await attemptFetch(endpoint, options, attempts + 1);
        } else {
            throw error;
        }
    }
}





