import React from 'react';
import {
  WiDaySunny, WiNightClear, WiDayCloudy, WiNightAltCloudy, WiCloudy, WiCloud,
  WiRain, WiSnow, WiSleet, WiFog, WiDayThunderstorm, WiDaySleetStorm, 
  WiDaySnowThunderstorm, WiDayShowers, WiDaySnow, WiDayRainMix, WiDayRainWind,
  WiSnowWind, WiDayLightning, WiDayHail, WiRainMix
} from 'react-icons/wi';

// Fallback component for unmapped weather codes
const UnmappedWeatherCode = ({ code }) => (
  <div className="unmapped-weather-code">
    {`Code: ${code}`}
  </div>
);

const weatherCodeToIcon = (code) => {
  const iconSize = 42; // Define the size for all icons
  const iconMapping = {
    '0': <WiDaySunny size={iconSize} />,                  // Clear sky
    '1': <WiDayCloudy size={iconSize} />,                 // Partly cloudy
    '2': <WiCloudy size={iconSize} />,                    // Cloudy
    '3': <WiCloud size={iconSize} />,                     // Overcast
    '45': <WiFog size={iconSize} />,                      // Fog
    '48': <WiFog size={iconSize} />,                      // Depositing rime fog
    '51': <WiDayShowers size={iconSize} />,               // Light drizzle
    '53': <WiDayShowers size={iconSize} />,               // Moderate drizzle
    '55': <WiRain size={iconSize} />,                     // Dense drizzle
    '56': <WiDaySleetStorm size={iconSize} />,            // Light freezing drizzle
    '57': <WiDaySleetStorm size={iconSize} />,            // Dense freezing drizzle
    '61': <WiDayRainMix size={iconSize} />,               // Slight rain
    '63': <WiDayRainWind size={iconSize} />,              // Moderate rain
    '65': <WiDayRainWind size={iconSize} />,              // Heavy rain
    '66': <WiDayRainWind size={iconSize} />,              // Light freezing rain
    '67': <WiRainMix size={iconSize} />,                  // Heavy freezing rain
    '71': <WiDaySnow size={iconSize} />,                  // Slight snow fall
    '73': <WiSnow size={iconSize} />,                     // Moderate snow fall
    '75': <WiSnowWind size={iconSize} />,                 // Heavy snow fall
    '77': <WiSleet size={iconSize} />,                    // Snow grains
    '80': <WiDayShowers size={iconSize} />,               // Slight rain showers
    '81': <WiDayRainMix size={iconSize} />,               // Moderate rain showers
    '82': <WiDayRainWind size={iconSize} />,              // Violent rain showers
    '85': <WiDaySnowThunderstorm size={iconSize} />,      // Slight snow showers
    '86': <WiSnowWind size={iconSize} />,                 // Heavy snow showers
    '95': <WiDayThunderstorm size={iconSize} />,          // Thunderstorm
    '96': <WiDayLightning size={iconSize} />,             // Thunderstorm with slight hail
    '99': <WiDayHail size={iconSize} />,                  // Thunderstorm with heavy hail
    'N0': <WiNightClear size={iconSize} />,               // Clear sky (night)
    'N1': <WiNightAltCloudy size={iconSize} />,           // Partly cloudy (night)
    // ...continue with other codes...
  };

  return iconMapping[code] || <UnmappedWeatherCode code={code} />;
};

export default weatherCodeToIcon;

  
  
  