// src/components/NewsFeed/NewsFeed.jsx
import React, { useState, useEffect } from 'react';
import { fetchNewsFromFunction } from '../../services/azureFunctions';
import '../../styles/NewsFeed.css';
import DOMPurify from 'dompurify';
import he from 'he';

const getHostname = (url) => {
    try {
        const newUrl = new URL(url);
        return newUrl.hostname.replace('www.', '');
    } catch (error) {
        //console.error('Error extracting hostname:', error);
        return '';
    }
};

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
};

const NewsFeed = () => {
    const [news, setNews] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const newsData = await fetchNewsFromFunction();

                // Get today's and yesterday's dates in YYYY-MM-DD format
                const today = new Date().toISOString().split('T')[0];
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                const yesterdayFormatted = yesterday.toISOString().split('T')[0];

                const todaysNews = newsData.filter(item => 
                    item.published_date.split('T')[0] === today);
                const yesterdaysNews = newsData.filter(item => 
                    item.published_date.split('T')[0] === yesterdayFormatted);

                // Shuffle each array
                shuffleArray(todaysNews);
                shuffleArray(yesterdaysNews);

                // Concatenate today's news with yesterday's
                const combinedNews = todaysNews.concat(yesterdaysNews);

                setNews(combinedNews);
            } catch (e) {
                setError('Failed to fetch news.');
                //console.error('Error fetching news:', e);
            }
        };

        fetchNews();
    }, []);
    
    const createMarkup = (htmlContent) => {
        const decodedContent = he.decode(htmlContent);
        return { __html: DOMPurify.sanitize(decodedContent) };
    }; 

    return (
        <div className="news-feed">
            {error && <p className="error">{error}</p>}
            <ul className="news-list">
                {news.map((item, index) => (
                    <li key={index} className="news-item">
                        <img src={item.image_url} alt={item.title} className="news-image" />
                        <div className="news-content">
                            <a href={item.link} target="_blank" rel="noopener noreferrer" className="news-title">
                                {item.title}
                            </a>
                            {/* Render sanitized HTML description */}
                            <div 
                                className="news-description" 
                                dangerouslySetInnerHTML={createMarkup(item.description)} 
                            />
                            <div className="news-info">
                                <div className="news-date">
                                    {new Date(item.published_date).toLocaleDateString(undefined, {
                                        year: 'numeric', month: 'long', day: 'numeric'
                                    })}
                                </div>
                                <div className="news-source">
                                    Source: {getHostname(item.link)}
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NewsFeed;






