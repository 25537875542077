import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavbarToggle, NavbarCollapse } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";

import { useIsAuthenticated } from "@azure/msal-react";
import { useUserSubscriptions } from "../../hooks/useUserSubscriptions";
import { SignOutButton } from "../Auth/SignOutButton";

export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const subscriptions = useUserSubscriptions();

    const dashboardPaths = {
        FarmingForumDashboard: "/dashboard/farmingforum",
    };
  
    return (
        <>
            <Navbar variant="dark" className="navbarStyle justify-content-between sticky-top" expand="lg">
                <Link className="navbar-brand px-3" to="/">
                    <img src="/headerLogo.png" alt="Agriverse Logo" height="15" />
                </Link>

                {isAuthenticated && (
                    <>
                        <NavbarToggle aria-controls="responsive-navbar-nav" />
                        <NavbarCollapse id="responsive-navbar-nav">
                            <Nav className="mx-auto">
                                <Dropdown>
                                    <Dropdown.Toggle className="nav-link">
                                        Dashboards
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {subscriptions.subscriptionData.map((sub) => (
                                            <Dropdown.Item as={Link} to={dashboardPaths[sub.DashboardName]} key={sub.DashboardName}>
                                                {sub.DashboardName.replace("Dashboard", "")}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Link className="nav-link" to="/account">
                                    Account
                                </Link>
                                <Nav.Link href="https://englandmarketing.qualtrics.com/jfe/form/SV_4VdMjw3Y5hXAX78" target="_blank" rel="noopener noreferrer">
                                    Help/Feedback
                                </Nav.Link>
                            </Nav>
                        </NavbarCollapse>
                    </>
                )}
                
                <div className="px-3">
                    {isAuthenticated && <SignOutButton />}
                </div>
            </Navbar>
            
            {props.children}
        </>
    );
};

